@import "../../../assets/styles/utils/variables";

.thumb {
    display: block;
    width: 100%;
    padding-top: 10px;

    .imgWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 261px;
        margin-bottom: 20px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 4px 4px rgba(11, 100, 169, 0.15);

        img {
            max-width: 100%;
        }
    }

    .contactBlock {
        margin-top: 20px;

        &:first-child {
            margin-top: 0;
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {
    .thumb {
        .imgWrapper {
            min-height: auto;

            img {
                &[src*=svg] {
                    margin: 19px auto;
                }
            }
        }
    }
}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
    .thumb {
        padding-bottom: 40px;

        .imgWrapper {
            img {
                width: 100%;

                &[src*=svg] {
                    width: auto;
                    margin: 19px auto;
                }
            }
        }
    }
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .thumb {
        padding-top: 0;

        .imgWrapper {
            margin-bottom: 12px;
            min-height: auto;

            img {
                width: 100%;

                &[src*=svg] {
                    width: auto;
                    margin: 19px auto;
                }
            }
        }

        .contactBlock {
            margin-top: 12px;
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}