@import "../../../assets/styles/utils/variables";

.titleBox {
    padding-bottom: 28px;

    h1 {
        color: $title-color;
        font-size: $font-size-h1;
        line-height: $line-height-h1;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h4 {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: $base-line-height;
        text-transform: uppercase;
        color: $text-color;
        letter-spacing: 0.05em;
    }

    .location {
        margin-bottom: 12px;
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .titleBox {
        padding-bottom: 20px;

        h1 {
            font-size: 2.0rem;
            line-height: 2.3rem;
            margin-bottom: 8px;
            text-transform: uppercase;
        }

        h4 {
            font-size: 1.2rem;
            line-height: 130%;
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}