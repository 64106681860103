@import "../../assets/styles/utils/variables";

.section {
    padding: 80px 0;

    .imgWrapper {
        margin-top: -40px;
    }

    .infographic {
        margin-top: 168px;
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
    .section {
        padding: 40px 0;

        .imgWrapper {
            margin-top: 0;
            margin-bottom: 40px;

            img {
                margin: 0 auto;
            }
        }

        .infographic {
            margin-top: 50px;
        }
    }
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .section {
        .imgWrapper {
            max-width: 292px;
            margin: 0 auto 20px;
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}