@import "../../assets/styles/utils/variables";

.Slider {
    [class~=swiper] {
        width: 100%;
        padding-bottom: 52px;
    }

    [class~=swiper-slide] {
        height: auto;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid $color-5;
        box-shadow: $shadow-1-start;
        opacity: 0.5;
        background-color: #fff;
        transition-property: opacity, box-shadow, border-color;
        transition-duration: $transition-duration;
        transition-timing-function: $transition-function;

        &:hover {
            opacity: 1 !important;
            box-shadow: $shadow-1 !important;
            border-color: transparent !important;
        }
    }

    [class~=non-swiper-slide] {
        display: none;
    }

    [class~=swiper-slide-active] {
        border-color: transparent;
        box-shadow: $shadow-1;
        opacity: 1;
    }

    [class~=swiper-pagination] {
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        
        [class~=swiper-pagination-bullet] {
            margin: 0 6px;
            width: 8px;
            height: 8px;
            background-color: $color-5;
            opacity: 0.5;
            // transition: $default-transition;
        }

        [class~=swiper-pagination-bullet-active] {
            width: 12px;
            height: 12px;
            background-color: $color-10;
            opacity: 1;
        }
    }

    &.sliderCustom {
        [class~=swiper-slide-active] {
            border-color: $color-5;
            box-shadow: $shadow-1-start;
            opacity: 0.5;
        }

        [class~=swiper-slide-next] {
            border-color: transparent;
            box-shadow: $shadow-1;
            opacity: 1;

            &+[class~=swiper-slide] {
                border-color: transparent;
                box-shadow: $shadow-1;
                opacity: 1;
            }
        }

        [class*=content] {
            padding: 16px;
        }

        [class*=text] {
            height: 72px;
            overflow: hidden;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            text-overflow: ellipsis;
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
    .Slider {
        [class~=swiper] {
            padding-bottom: 32px;
        }

        &.sliderCustom {
            [class~=swiper-slide-active] {
                opacity: 1;
            }
        }
    }
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .Slider {
        &.sliderCustom {
            [class~=swiper-slide-next] {
                opacity: 0.5;
            }
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {
    .Slider {
        &.sliderCustom {
            [class~=swiper-slide-active] {
                border-color: transparent;
                box-shadow: $shadow-1
            }
        }

        [class~=swiper] {
            margin: -20px 0;
        }

        [class~=swiper-slide] {
            padding: 20px 0;
            box-shadow: none !important;
            border: none;
            background-color: transparent;
            border-radius: 0px;
        }
    }
}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}