@import "../../assets/styles/utils/variables";

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 39px;
    max-width: 328px;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    background: $color-10;
    font-size: $font-size-h6;
    line-height: $line-height-h6;
    font-weight: 400;
    color: $color-8;
    transition: $default-transition;

    &:hover {
        background-color: $color-2;
    }
}