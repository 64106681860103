@import "../../../assets/styles/utils/variables";
@import "../../../assets/styles/utils/mixins";

.TextBox {
    color: $text-color;
    font-size: $base-font-size;
    line-height: $base-line-height;

    [class~=blueText] {
        color: $color-2;
    }

    [class~=titleWord] {
        font-weight: 700;
        font-size: $font-size-h5;
    }

    p, li {	
        @include font($base-font-size,$base-line-height,$text-color);
        font-weight: 400;	
    }

    p {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    li {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1 {	
        @include font($font-size-h2,$line-height-h2,$title-color);
        font-family: $title-font-family;
        font-weight: 700;
        margin-bottom: 12px;
    }

    h2 {
        @include font($font-size-h2,$line-height-h2,$color-2);
        font-family: $title-font-family;
        font-weight: 700;
        margin-bottom: 12px;
    }
    h3 {
        @include font($font-size-h3,$line-height-h3,$color-2);
        font-family: $title-font-family;
        font-weight: 700;
        margin-bottom: 12px;
    }
    
    h4 {	
        @include font($font-size-h4,$line-height-h4,$color-2);
        font-family: $title-font-family;
        font-weight: 700;
        margin-bottom: 12px;
    }
    h5 {
        @include font($font-size-h5,$line-height-h5,$color-2);
        font-family: $title-font-family;
        font-weight: 700;
        margin-bottom: 12px;
    }
    h6 {
        @include font($font-size-h6,$line-height-h6,$color-2);
        font-family: $title-font-family;
        font-weight: 700;
        margin-bottom: 12px;
    }

    ol, ul {
        margin-bottom: 20px;
    }

    ul {    
        li {
            position: relative;
            padding-left: 28px;

            &::before {
                content: '';
                display: block;
                width: 5px;
                height: 5px;
                background: $color-2;
                position: absolute;
                top: 9px;
                left: 8px;
                border-radius: 50%;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ol {
        counter-reset: myCounter;

        li {
            counter-increment: myCounter;
            padding-left: 28px;
            position: relative;

            &:before {
                content: counter(myCounter) ".";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                font-weight: 700;
                color: $color-2;
            }
        }
    }

    table {
        width: 100%;
        margin-bottom: 26px;

        &:last-child {
            margin-bottom: 0;
        }

        td, th {
            @include font($base-font-size, $base-line-height, $text-color);
            font-weight: 400;
            color: $text-color;
            border: $border;
            padding: 5px 10px;
        }

        th {
            background-color: $color-5;
            border-bottom-color: $color-3;
            font-weight: 700;
        }
    }

    img, figure, picture {
        max-width: 100%;
        width: auto;
        margin: 24px 0;

        &[class~=alignright] {
            float: right;
            margin: 24px 0 24px $grid-gap*2;
        }

        &[class~=alignleft] {
            float: left;
            margin: 24px $grid-gap 24px 0;
        }

        &[class~=aligncenter] {
            margin: 24px auto;

            img {
                max-width: 100%;
            }
        }

        &[class~=alignnone] {
            margin: 24px 0 24px;

            img {
                max-width: 100%;
            }
        }

        &:first-child, &[class~=alignright]:first-child, &[class~=alignleft]:first-child, &[class~=alignnone]:first-child {
            margin-top: 0;
        }
    }

    blockquote {
        display: block;
        padding: 16px;
        border-left: 5px solid $color-2;
        background-color: $color-5;
        margin: calc(26px * 2) 0;
        color: $text-color;
        clear: both;

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 10px;
            color: $text-color;
        }

        a {
            text-decoration: underline;
            color: $text-color;
        }
    }

    video {
        display: block;
        width: 100%;
    }

    iframe {
        display: block;
        width: 100%;
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .TextBox {
        p, li {
            font-size: 1.4rem;
            line-height: 2.1rem;
        }

        li {
            line-height: 150%;
        }

        p {
            margin-bottom: 21px;
        }

        h1 {
            font-size: 2.0rem;
            line-height: 130%;
            text-transform: uppercase;
        }

        h2 {
            font-size: 2.0rem;
            line-height: 130%;
        }

        h3 {
            font-size: 2.0rem;
            line-height: 130%;
        }

        h4 {
            font-size: 2.0rem;
            line-height: 130%;
        }

        h5 {
            font-size: 2.0rem;
            line-height: 130%;
        }

        h6 {
            font-size: 1.6rem;
            line-height: 130%;
        }

        ul {
            li {
                &::before {
                    top: 8px;
                }
            }
        }

        ol {
            li {
                &::before {
                    font-size: 1.4rem;
                    line-height: 150%;
                }
            }
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}