/* reset */
$min-width: 320px; 
$max-width: 1230px; 
$grid-gap: 15px;
$base-bg: #FFF; 
$base-font-size: 1.6rem; 
$base-line-height: 150%; 
$base-font-family: 'HeliosC', sans-serif; 
$custom-font-family: 'Roboto', sans-serif; 
$decor-font-family: 'HeliosC', sans-serif; 

$transition-duration: 0.2s;
$transition-function: linear;
$default-transition: all $transition-duration $transition-function;

$screen-xlg-max: 1840px;

$screen-xl-min: 1260px;
$screen-lg-max: 1259px;
$screen-lg-min: 992px;
$screen-md-max: 991px;
$screen-md-min: 768px;
$screen-sm-max: 767px;
$screen-sm-min: 576px;
$screen-xs-max: 575px;
$screen-xs-min: 320px;
$screen-xxs-max: 319px;

/* main variables */

// colors 
$color-1: #E46B33;
$color-2: #0B64A9;
$color-3: #07426F;
$color-4: #4B4D4C;
$color-5: #C1CBD8;
$color-6: #F2F4F7;
$color-7: #FCFDFF;
$color-8: #FFFFFF;
$color-9: #F3F3F3;
$color-10: #F36D2F;
$color-11: #F2F4F7;


//text, titles
$text-color:      $color-4;
$title-color:     $color-3;
$title-font-family: $base-font-family;

$link-color: $color-2;
$link-hover: $color-1;

$font-size-h1:   3.6rem;
$line-height-h1: 120%;
$font-size-h2:   3.2rem;
$line-height-h2: 120%;
$font-size-h3:   2.8rem;
$line-height-h3: 120%;
$font-size-h4:   2.4rem;
$line-height-h4: 2.8rem;
$font-size-h5:   2.0rem;
$line-height-h5: 2.6rem;
$font-size-h6:   1.6rem;
$line-height-h6: 1.9rem;

//borders
$border-color: #efefef;
$border:         1px solid $border-color;

//shadows
$shadow-1-start: 0px 0px 0px rgba(8, 68, 90, 0.05);
$shadow-1: 6px 12px 30px rgba(8, 68, 90, 0.05);
$shadow-2: 0px 12px 30px rgba(7, 66, 111, 0.12);

//gradients
$gradient-1: linear-gradient(180deg, #0B64A9 0%, #4DADF8 100%);
$gradient-2: linear-gradient(180deg, #E46B33 0%, #FFAE88 100%);
$gradient-3: linear-gradient(90deg, #DFDFF7 43.45%, #BFBFEE 52%, #BFBFEE 52.01%, #BFBFEE 59.41%);
$gradient-4: linear-gradient(241.85deg, #F15A24 16.95%, #FFBA00 83.05%);
