@import '../../assets/styles/utils/variables';

.main {	
	min-width: $min-width;
	min-height: 100vh;
    width: 100%;
	order: 2;
	flex-grow: 1;
	text-align:left;
	background: $color-8;
}