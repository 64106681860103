@import "../../../assets/styles/utils/variables";

.nav {
    display: flex;
    align-items: flex-start;
    margin: -25px;

    &__item {
        flex: 0 0 auto;
        margin: 25px;

        &.bigNav {
            column-count: 3;
            column-gap: 50px;
            flex: 1 1 auto;
        }

        li {
            margin-bottom: 17px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    a {
        color: $color-8;
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 700;
        text-transform: capitalize;

        &:hover {
            text-decoration: underline;
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {
    .nav {
        &__item {
            display: none;

            &:first-child {
                display: flex;
                margin: -16px;
            }

            li {
                margin: 16px;
            }
        }
    }
}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {

}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}