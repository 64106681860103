@import "../../../assets/styles/utils/mixins";
@import "../../../assets/styles/utils/variables";

@keyframes rotationLoader {
    0% {
        transform: rotate(0deg);
    }
  
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba($color-8, 1);

    @media (max-width: $screen-md-max) {
        background-color: rgba($color-8, .6);
    }
}

@keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}

@keyframes rotationBack {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(-360deg) }
}

.container {
    @include fixAnimationsOverflow();
    overflow: hidden;
    position: relative;

    width : 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: $color-2 $color-2 transparent transparent;
    animation: rotation 1s linear infinite;

    &:after , &:before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent $color-1 $color-1;
        width:  100px - 8px;
        height:  100px- 8px;
        border-radius: 50%;
        animation: rotationBack 0.5s linear infinite;
        transform-origin: center center;
    }
    &:before{
        width:  100px - 16px;
        height:  100px - 16px;
        border-color: $color-2 $color-2 transparent transparent;
        animation: rotation 1.5s linear infinite;
    }
}