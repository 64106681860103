@import "../../assets/styles/utils/variables";

.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    min-height: 60vh;
    font-family: $custom-font-family;
    color: $link-color;

    h1 {
        font-size: 8.2rem;
        font-weight: 400;
        margin-bottom: calc($grid-gap * 2);
    }

    p {
        font-size: 4.2rem;
        line-height: 130%;        
    }

    button {
        margin-top: calc($grid-gap * 4);
    }
}