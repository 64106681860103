@import "../../../assets/styles/utils/variables";

.imageMenu {
    display: none;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 100%;
    padding: 30px 30px 30px 37px;
    margin: -15px -15px -15px -22px;
    min-width: 667px;
    height: 100%;
    z-index: 9;

    &.shown {
        display: flex;
    }

    li {
        flex: 0 0 170px;
        margin: $grid-gap;

        .imgWrapper {
            display: block;
            width: 100%;
            border-radius: 10px;
            overflow: hidden;

            img {
                transition: $default-transition;
                
                &[src*=svg] {
                    display: block;
                    max-width: 71px;
                    min-height: 120px;
                    margin: 0 auto;
                }
            }
        }

        a {
            display: block;
            padding-bottom: 0;
            background-color: #fff;
            border-radius: 10px;
            padding-bottom: 8px;
            box-shadow: $shadow-1;

            span {
                color: $title-color;
                margin: 6px 0 0 0;
                padding: 0 8px 0 8px;
                font-size: 1.4rem;
                line-height: 130%;
                font-weight: 700;
                height: 37px;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                text-overflow: ellipsis;
            }

            &:hover {
                .imgWrapper img {
                    transform: scale(1.2);
                }
            }
        }
    }
}