@import "./assets/styles/utils/variables";

.App {
    box-sizing: border-box;
    padding-top: 100px;
}

//main styles

body {
	&.blocked {
		overflow: hidden;
		height: 100%;
	}
}

.main-wrapper {
	padding: 0 0 0 0;
	min-width: $min-width;
    width: 100%;
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;	
}

.fadeIn {
    animation: 0.5s fadeIn forwards;
}

.fadeOut {
    animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* text position */
.text-left {
	text-align: left !important;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.nowrap {
	white-space: nowrap !important;
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
    .App {
        padding-top: 84px;
    }
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {

}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}