@import "../../../assets/styles/utils/variables";

.content {
    padding: 297px 0 228px;
    position: relative;
    z-index: 2;

    &Container {
        display: flex;
        flex-direction: column;
        max-width: 570px;
    }

    .title {
        margin-bottom: 20px;
        font-family: $title-font-family;
        font-weight: 700;
        font-size: 4.0rem;
        line-height: 150%;
        color: $title-color;
    }

    .text {
        font-weight: 400;
        font-family: $base-font-family;
        font-size: $base-font-size;
        line-height: $base-line-height;
    }

    .button {
        margin-top: 24px;
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {
    .content {
        padding: 200px 0;
    }
}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .content {
        padding: 112px 0 244px;
        max-width: 370px;

        .title {
            font-size: 2.4rem;
            line-height: 140%;
            margin-bottom: 12px;
        }

        .text {
            font-size: 1.4rem;
            line-height: 150%;
        }

        .button {
            margin-top: 12px;
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}