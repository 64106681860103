@import "../../../assets/styles/utils/variables";

.SubMenu {
    display: none;
    position: absolute;
    top: 100%;
    left: -93px;
    min-height: 430px;
    background: $color-8;
    padding: $grid-gap*2 0 $grid-gap*2 0;

    &::before {
        content: '';
        display: block;
        height: 100%;
        width: 250vw;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-8;
        box-shadow: inset $shadow-1, $shadow-2;
    }

    &::before {
        right: 0;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% - ($grid-gap*4));
        background-color: $color-6;
    }

    &.shown {
        display: block;
    }

    & > li {
        width: 350px;
        position: static;
        padding-right: 21px;

        & > a {
            display: block;
            width: 100%;
            padding: 9px 32px 10px 16px;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.1rem;
            color: $title-color;
            border-bottom: 1px solid $color-6;
            
            svg {
                display: none;
            }
        }

        &:first-child {
            & > a {
                border-top: 1px solid $color-6;
            }
        }

        &:hover {
            & > a {
                background-color: $color-2;
                color: $color-8;
            }
        }

        &.hasSubMenu {
            & > a {
                position: relative;

                svg {
                    display: block;
                    position: absolute;
                    width: 14px;
                    height: 8px;
                    top: 50%;
                    right: 11px;
                    margin-top: -4px;
                    transform: rotate(-90deg);

                    path {
                        fill: $color-3;
                        transition: $default-transition;
                    }
                }
            }

            &:hover {
                ul {
                    display: flex;
                }

                a {
                    svg {
                        path {
                            fill: $color-8;
                        }
                    }
                }
            }
        }
    }
}