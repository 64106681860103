@import "../../../assets/styles/utils/variables";

.link {
    display: flex;
    align-items: center;
    color: $color-2;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-transform: uppercase;

    svg {
        flex: 0 0 17px;
        height: 14px;
        margin-left: 16px;
        margin-top: -2px;

        path {
            transition: $default-transition;
        }
    }

    &:hover {
        color: $link-hover;

        svg {
            path {
                fill: $link-hover;
            }
        }
    }
}