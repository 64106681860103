@import "../../../assets/styles/utils/variables";

.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .email {
        color: #fff;
        display: block;
        position: relative;
        padding-left: 19px;
        margin-top: 16px;
        font-family: $custom-font-family;

        svg {
            content: '';
            display: block;
            width: 14px;
            height: 11px;
            position: absolute;
            top: 3px;
            left: 0;
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
    .contacts {
        flex-direction: row;
        align-items: center;

        .email {
            margin-top: 0;
            margin-left: 18px;
        }
    }
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .contacts {
        .email {
            font-size: 1.4rem;
            line-height: 1.9rem;
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}