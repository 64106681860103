@import "../../assets/styles/utils/variables";

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -6px;

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: transparent;

        &.buttonPrev {
            margin-right: -25px;

            svg {
                transform: rotate(90deg);
            }
        }

        &.buttonNext {
            margin-left: -25px;

            svg {
                transform: rotate(-90deg);
            }
        }

        &:hover {
            background-color: transparent;
            transform: scale(1.2);
        }
    }

    li {
        min-width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        cursor: pointer;

        a, span {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;
            height: 40px; padding: 0 6px;
            background-color: rgba($color-2, 0);
            border-radius: 6px;
            color: #000;

            &:hover, &.active {
                background-color: rgba($color-2, .1);
                color: $link-color;
            }
        }

        &.selected {
            a, span {
                background-color: rgba($color-2, .1);
                color: $link-color;
            } 
        }

        &.disabled {
            opacity: 0.5;
            cursor: default;
        }

        &.dots {
            cursor: default;

            &:hover {
                span {
                    background-color: transparent;
                }
            }
        }
    }
}