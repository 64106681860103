@import "../../../assets/styles/utils/variables";

.item {
    display: flex;
    flex-direction: column;
    background: $color-8;
    padding: 16px 16px 18px;
    box-shadow: $shadow-1;
    border-radius: 10px;
    height: 100%;

    .iconWrapper {
        width: 40px;
        height: 40px;
        margin-bottom: 12px;

        svg {
            display: block;
            width: 100%;
        }
    }

    .title {
        margin-bottom: 8px;
        font-family: $title-font-family;
        color: $title-color;
        font-weight: 700;
        text-transform: uppercase;
        font-size: $font-size-h5;
        line-height: 2.3rem;
    }

    .descr {
        line-height: 130%;
        margin-bottom: 18px;
        color: $text-color;
    }

    .link {
        margin-top: auto;
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .item {
        flex-direction: row;
        align-items: center;
        padding: $grid-gap $grid-gap $grid-gap 12px;

        .iconWrapper {
            flex: 0 0 40px;
            margin-bottom: 0;
            margin-right: 8px;
        }

        .title {
            margin-bottom: 0;
            flex: 1 1 auto;
            font-size: 1.4rem;
            line-height: 1.6rem;
        }

        .descr {
            display: none;
            margin-bottom: 0;
        }

        .link {
            font-size: 0;
            margin-top: 0;
            flex: 0 0 17px;
            margin-left: 8px;

            svg {
                margin-left: 0;
            }
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {
    .item {
        .title {
            font-size: $font-size-h6;
            line-height: $line-height-h6;
        }
    }
}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}