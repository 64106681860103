@import "../../../assets/styles/utils/variables";

.subMenu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: $grid-gap*6 20px;
    background-color: $color-8;
    z-index: 999;

    &[data-active=true] {
        display: block;
    }

    ul {
        & > li {
            border-bottom: none;
            margin-top: 15px;
    
            & > a, & > span {
                color: $link-color;
                font-size: 1.8rem;
                line-height: 2.4rem;
                font-weight: 400;
            }
    
            &:first-child {
                margin-top: 20px;
                margin-bottom: 20px;
                display: flex;
    
                a, span {
                    font-size: 2.2rem;
                    line-height: 3.0rem;
                    font-weight: 400;
                    background-color: $color-8;
                    color: $link-color;
                    position: relative;
                    border-bottom: 1px solid $color-1;
    
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.return {
    position: absolute;
    top: 40px;
    left: 20px;
    border: none;
    z-index: 999;
    padding-left: 16px;
    text-transform: uppercase;
    background-color: transparent;
    font-size: 1.4rem;
    line-height: 24px;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        width: 8px;
        height: 8px;
        border-right: 8px solid $link-color;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        flex: 0 0 auto;
        margin-top: 4px;
    }
}