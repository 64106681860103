@import "../../assets/styles/utils/variables";

.head {
    display: block;
    width: 100%;
    margin-bottom: 40px;

    .title {
        margin-bottom: 14px;
    }

    .subtitle {
        margin: 14px 0;
    }

    .imgWrapper {
        margin-top: 48px;

        img, svg {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
    .head {
        display: flex;

        .text {
            flex: 1 1 50%;
        }

        .imgWrapper {
            flex: 0 0 50%;
            margin-top: 0;
        }
    }
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .head {
        flex-direction: column;
        margin-bottom: 20px;

        .title {
            margin-bottom: 8px;
        }

        .subtitle {
            margin: 0;
        }

        .text {
            flex: 0 0 auto;
        }

        .imgWrapper {
            flex: 0 0 auto;
            margin: 20px auto 0;
            max-width: 328px;
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}