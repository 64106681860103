@import "../../../assets/styles/utils/variables";

.Hamburger {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border: none;
    padding: 6px 0;
    width: 24px;
    height: 24px;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 200%;
        height: 200%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $color-2;
        border-radius: 2px;
        margin-bottom: 3px;

        &:nth-child(2) {
            width: 75%;
        }

        &:last-child {
            width: 50%;
            margin-bottom: 0;
        }
    }
}