@import "../../assets/styles/utils/variables";

.slider {
    max-width: 1058px;
    margin: 0 auto;

    .sliderLink {
        display: block;
        width: 100%;
        transition: $default-transition;
        filter: invert(89%) sepia(16%) saturate(191%) hue-rotate(175deg) brightness(90%) contrast(90%);;
        
        .imgWrapper {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        &:hover {
            filter: invert(0%) sepia(4%) saturate(15%) hue-rotate(64deg) brightness(99%) contrast(104%);
        }
    }

    [class~=swiper-wrapper] {
        align-items: center;
    }
}