@import '../utils/variables';
@import '../utils/mixins';
@import './flex-grid';

body {
	margin: 0px;
	padding: 0px;
	font-family: $base-font-family, sans-serif;
	background-color: $base-bg;
	height: 100%;
	line-height: 1;
	position: relative;
	font-size: $base-font-size !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/*MAIN*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, 
form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer,
header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0; padding: 0; border: 0; font-size: 100%; 
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
	display: block;
}
blockquote, q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-width: 0px;
	padding: 0px;
	margin: 0px;
}

html {
	height: 100%;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	font-size: 62.5%;
}

input,
textarea {
	color: #333;
	font-family: $base-font-family;
	outline: none;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
}

input[type="button"],
input[type="submit"],
button {
	cursor: pointer
}

td {
	margin: 0px;
	padding: 0px;
}

form {
	padding: 0px;
	margin: 0px;
}

a, button {
	color: $link-color;
	@include transition($default-transition);
	text-decoration: none;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
span,
div,
button {
	outline: none!important
}

input[type=submit],
input[type=button],
button {
	-webkit-appearance: none;
	outline: none;
	padding: 0;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

// .clearfix:after,
// .wrapper:after,
// .row:after{
// 	content: ".";
// 	display: block;
// 	height: 0;
// 	clear: both;
// 	visibility: hidden;
// }

ul, li, ol {
	list-style-type: none;
}

img{
	display: block;
	max-width: 100%;
	height: auto;
}

picture {
	display: block;
	max-width: 100%;
	height: auto;

	img {
		width: 100%;
	}
}

input,
button {
	font-family: $base-font-family, sans-serif;
// 	&:focus {
// 		outline: 5px auto rgba(0, 150, 255, 1) !important;
// 	}
}

.has-inline-color {
	&.has-base-color {
		color: #fff;
	}
	&.has-contrast-color {
		color: #000;
	}
	&.has-primary-color {
		color: #e36a32;
	}
	&.has-secondary-color {
		color: #0b64a9;
	}
	&.has-tertiary-color {
		color: #4b4d4c;
	}
}