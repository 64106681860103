@import "../../../assets/styles/utils/variables";

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 999;
    right: 20px;
    bottom: 20px;
    font-size: 0;
    background-color: $color-8;
    border: none;
    width: 40px;
    height: 40px;
    transition: $default-transition;

    svg {
        display: block;
        transform: rotate(-90deg);

        path {
            fill: $color-1;
            transition: $default-transition;
        }
    }

    &:hover {
        background-color: $color-1;

        svg {
            path {
                fill: $color-8;
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        right: 20px;
        box-shadow: 4px 5px 12px rgb(0 0 0 / 26%);
    }
}