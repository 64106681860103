@import "../../../assets/styles/utils/variables";

.boxField {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
}

.textAreaControl {
    display: block;
    width: 100%;
    height: 140px;
    background-color: $color-6;
    border: 1px solid $color-6;
    border-radius: 20px;
    padding: 12px 16px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $text-color;
    resize: none;
    
    &::placeholder {
        color: $color-5;
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .boxField {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}