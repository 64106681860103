@import "../../../assets/styles/utils/mixins";
@import "../../../assets/styles/utils/variables";

.slider {
    @include absolute-center;
    @include copy-size;
    transition: all .3s;

    .bg {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 48.8vw;
        opacity: 0;
        transform: translateY(20px);
        transition: all .8s;
    }

    .img {
        max-width: 51.0vw;
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0;
        transition: all 1s;        
    }

    [class~=swiper] {
        @include absolute-center;
        @include copy-size;
    }

    [class~=swiper-slide-active] {
        .bg {
            opacity: 1;
            transform: none;
            transition-delay: .3s;
        }

        .img {
            opacity: 1;
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {
    .slider {
        .bg {
            max-width: 49vw;
        }


        .img {
            max-width: 50vw;
        }
    }
}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
    
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .slider {
        .bg {
            max-width: 64.236vw;
        }

        .img {
            max-width: 67.708vw;
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {
    .slider {
        .bg {
            max-width: 86vw;
        }

        .img {
            max-width: 90.666vw;
        }
    }
}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}