@import "../../../assets/styles/utils/variables";

.Location {
    display: flex;
    color: $color-2;
    font-size: 1.2rem;
    line-height: 130%;

    svg {
        content: '';
        display: block;
        flex: 0 0 16px;
        height: 16px;
        margin-top: 0px;
    }
}