@import '../../assets/styles/utils/variables';

.footer {
	order: 3;
	width: 100%;
	padding: 40px 0;
	min-width: $min-width;
	background: $color-2;
	text-align: left;
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translate(0, 100%);
	z-index: 99;
	
	&.active {
		transform: translate(0, 0%);
	}

	.container {
		display: flex;
		margin: 0 -#{$grid-gap};
	}

	.logo {
		flex: 0 0 170px;
		margin: 0 $grid-gap;
	}

	.nav {
		flex: 1 1 auto;
		margin: 28px $grid-gap 0;

		ul {
			&:nth-child(2) {
				min-width: 249px;
			}

			&:nth-child(3) {
				min-width: 148px;
			}
		}
	}

	.contacts {
		margin: $grid-gap*2 $grid-gap 0;
	}

	.disclaimer {
		margin-top: $grid-gap*2;
	}
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {
	.footer {
		.nav {
			padding-top: $grid-gap*2;
		}
	}
}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
	.footer {
		.container {
			flex-direction: column;
			align-items: center;
		}

		.logo {
			flex: 0 0 auto;
		}

		.nav {
			flex: 0 0 auto;
			padding-top: 0;
			margin: 64px 0 0;
		}

		.disclaimer {
			margin-top: 40px;
		}
	}
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
	.footer {
		padding: $grid-gap*2 0 40px;

		.logo {
			max-width: 97px;

			svg {
				width: 100%;
			}
		}
	}
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}