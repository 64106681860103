@import "../../assets/styles/utils/variables";

.navMobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $color-8;
    z-index: 999;
    transition: $default-transition;

    &[data-active=true] {
        display: block;
    }

    .logo {
        position: fixed;
        top: 8px;
        right: 20px;
    }

    & > button {
        position: absolute;
        display: block;
        top: 29px;
        left: 14px;
        width: 45px;
        height: 45px;
        background-color: $color-8;
        border: none;
        font-size: 0;
        z-index: 999;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 150%;
            height: 150%;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            display: block;
            width: 70%;
            height: 4px;
            background-color: $link-color;
            border-radius: 2px;

            &:last-child {
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }

    &[class~=slideIn] {
        margin-left: 0;
    }

    &[class~=slideOut] {
        margin-left: -100vw;
    }
}

.navList {
    padding: $grid-gap*6 24px;
    
    & > li {
        margin-top: 30px;

        & > a, & > span {
            padding: 0;
            font-size: 2.0rem;
            line-height: 28px;
            color: $link-color;
            font-family: $custom-font-family;
            font-weight: 300;
        }

        &.hasSubMenu {
            & > span {
                display: flex;
                justify-content: flex-start;
                position: relative;

                svg {
                    content: '';
                    flex: 0 0 auto;
                    display: block;
                    width: 16px;
                    height: 8px;
                    margin-left: 10px;
                    right: $grid-gap*3;
                    margin-top: 11px;
                    transform: rotate(-90deg);
                }
            }
        }
    }
}