@import "../../../assets/styles/utils/variables";

.link {
    &.emailType {
        color: $text-color;
    }

    &.socialType {
        display: block;
        width: 24px;
        height: 24px;
        font-size: 0;

        svg {
            display: block;
            
            path {
                transition: $default-transition;
            }

            &:hover {
                path {
                    fill: $color-1;
                }
            }
        }
    }

    &.buttonType {
        display: flex;
        width: 200px;
        min-height: 39px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background: $color-10;
        border: none;
        border-radius: 20px;
        font-family: $base-font-family;
        font-style: normal;
        font-weight: 400;
        font-size: $base-font-size;
        line-height: $line-height-h6;
        color: $color-8;

        &:hover {
            background-color: $color-2;
        }
    }
}