@import "../../../assets/styles/utils/variables";

.text {
    max-width: 598px;
    margin: 0 auto;
    text-align: center;
    color: $color-8;
    font-size: 1.4rem;
    font-family: $custom-font-family;
    line-height: $base-line-height;

    a {
        color: $color-8;
        text-decoration: underline;
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .text {
        font-size: 1.2rem;
        line-height: 150%;
        color: rgba($color-8, .6);
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}