@import "../../assets/styles/utils/variables";

.form {
    display: flex;
    flex-direction: column;

    .message {
        display: block;
        width: 100%;
        font-style: italic;
        font-weight: 400;
        margin-top: 24px;
        line-height: 130%;
        font-weight: normal;
        font-family: $custom-font-family;
        color: rgba($text-color, .7);
        border-left: 3px solid $color-2;
        padding: 7px 0 7px 10px;

        &[data-status=mail_failed] {
            border-color: rgb(227, 87, 87);
        }

        &[data-status=validation_failed] {
            border-color: $color-1;
        }
    }

    .captcha {
        margin-bottom: 24px;
    }
}