@import "../../../assets/styles/utils/variables";

.filter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 36px;

    .filterButton {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $color-5;
        position: relative;
        padding: 9px 30px;
        color: $color-2;
        font-size: $base-font-size;
        line-height: 130%;

        &::after {
            content: '';
            display: block;
            width: 0%;
            height: 1px;
            position: absolute;
            bottom: -1px;
            left: 0;
            background-color: $color-2;
            transition: $default-transition;
        }

        &[data-active=true] {
            font-weight: 700;

            &::after {
                width: 100%;
            }
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .filter {
        margin-bottom: 20px;
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}