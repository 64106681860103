@import "../../../assets/styles/utils/variables";

.Button {
    display: flex;
    width: 200px;
    min-height: 39px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: $color-10;
    border: none;
    border-radius: 20px;
    font-family: $base-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: $base-font-size;
    line-height: $line-height-h6;
    color: $color-8;

    &:hover {
        background-color: $color-2;
    }
}