@import "../../../assets/styles/utils/variables";

.line {
    display: flex;
    font-size: 1.4rem;
    line-height: 1.6rem;

    svg {
        content: '';
        display: block;
        flex: 0 0 24px;
        height: 24px;
        margin-right: 12px;
    }

    span {
        display: block;
        flex: 1 1 auto;
        padding-top: 4px;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .line {
        font-size: 1.2rem;
        line-height: 1.4rem;

        &::before {
            flex: 0 0 23px;
            height: 23px;
        }

        span {
            padding-top: 5px;
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}