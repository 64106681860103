@import "../../../assets/styles/utils/variables";

.Item {
    display: flex;
    align-items: center;
    justify-content: center;

    .imgWrapper {
        height: 118px;
        flex: 0 0 auto;
        margin-right: 30px;
    }

    .content {
        flex: 0 0 auto;
        color: $color-2;
        line-height: 140%;
        font-size: $base-font-size;

        &__title {
            font-size: 6.0rem;
            font-weight: 700;
        }

        &__text {
            margin-top: 25px;
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
    .Item {
        margin-top: 40px;

        .imgWrapper {
            display: flex;
            justify-content: flex-end;
            width: 115px;
            height: 117px;
        }

        .content {
            &__text {
                width: 150px;
            }
        }
    }
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {

}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}