@font-face {
    font-family: 'HeliosC';
    src: url('../../fonts/HeliosC/HeliosC-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HeliosC';
    src: url('../../fonts/HeliosC/HeliosC.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HeliosC';
    src: url('../../fonts/HeliosC/HeliosC-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto/Roboto-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto/Roboto-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}