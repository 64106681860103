@import "../../../assets/styles/utils/variables";

.Slide {
    display: flex;
    min-height: 100%;

    .imgWrapper {
        flex: 0 0 270px;
        position: relative;
        margin-top: -1px;
        margin-left: -1px;
        border-radius: 10px;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
        }

        @media (max-width: $screen-xlg-max) {
            flex: 0 0 216px
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 12px 16px 18px 12px;
        font-size: 1.4rem;
        line-height: 130%;
        min-height: 100%;

        .title {
            font-size: $font-size-h6;
            color: $title-color;
            margin-bottom: 4px;
            height: 36px;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            text-overflow: ellipsis;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .text {
            margin-bottom: 12px;
            color: $text-color;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            text-overflow: ellipsis;

            &:last-child {
                margin-bottom: 0;
            }
        }

        [class*=Location] {
            margin: auto 0 12px;
        }

        @media (max-width: $screen-xlg-max) {
            padding: 3px 16px 3px 12px;
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .Slide {
        .imgWrapper {
            flex: 0 0 266px;
        }

        .content {
            padding: 16px;

            [class*=Location] {
                margin: auto 0 8px;
            }
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {
    .Slide {
        flex-direction: column;
        background-color: $color-8;
        box-shadow: $shadow-1;
        border-radius: 10px;
        max-width: 328px;
        margin: 0 auto;

        .imgWrapper {
            flex: 0 0 auto;
        }
    }
}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}