@import "../../../assets/styles/utils/variables";

.item {
    display: block;
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    .itemLink {
        display: flex;
        background-color: $color-8;
        box-shadow: $shadow-1;
        border-radius: 10px;
        align-items: flex-start;
    }

    .imgWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 270px;
        overflow: hidden;
        border-radius: 10px;
        background-color: $color-8;

        img {
            transition: $default-transition;
        }

        &.icon {
            flex: 0 0 170px;
            min-height: 120px;

            img {
                max-width: 71px;
            }
        }
    }

    .content {
        flex: 1 1 auto;
        overflow: hidden;
        padding: 16px 16px 19px 16px;

        .title {
            display: block;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: $font-size-h4;
            line-height: 130%;
            color: $title-color;
            margin-bottom: 4px;
        }

        .subtitle {
            color: $text-color;
            letter-spacing: 0.05em;
            font-size: 1.2rem;
            line-height: 130%;
            text-transform: uppercase;
            max-height: 16px;
            overflow: hidden;
        }

        .readMore {
            margin-top: 13px;
        }

        .location {
            margin: 5px 0 12px;
        }

        &.smallText {
            padding: 16px;

            .title {
                font-size: $base-font-size;
                text-overflow: initial;
                white-space: normal;
                max-height: 42px;
                overflow: hidden;
            }

            .subtitle {
                font-size: 1.4rem;
                text-transform: none;
                max-height: 36px;
            }

            .readMore {
                margin-top: 12px;
            }
        }
    }

    &:hover {
        .imgWrapper {
            img {
                transform: scale(1.1);
            }
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .item {
        max-width: 327px;
        margin: 0 auto 20px;

        .itemLink {
            flex-direction: column;
        }

        .imgWrapper {
            width: 100%;
            flex: 0 0 auto;

            img {
                width: 100%;
            }
        }

        .content {
            padding: 16px 16px 18px 16px;
            width: 100%;

            .readMore {
                display: none;
                margin-top: 10px;
            }

            .title {
                font-size: 1.6rem;
                line-height: 130%;
            }

            .subtitle {
                font-size: 1.2rem;
            }

            &.smallText {
                .subtitle {
                    max-height: 90px;
                }

                .readMore {
                    margin-top: 10px;
                }
            }
        }

        &.hasImage {
            .content {
                .readMore {
                    display: flex;
                }
            }
        }

        &.hasIcon {
            .itemLink {
                flex-direction: row;
            }

            .icon {
                flex: 0 0 120px;
                min-height: 126px;
            }

            .content {
                .title {
                    text-overflow: unset;
                    white-space: unset;
                }
            }
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}