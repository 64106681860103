@import "../../assets/styles/utils/variables";

.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;

    li {
        display: flex;
        margin-right: 18px;
        font-size: 1.4rem;
        line-height: 1.6rem;
        text-transform: capitalize;

        svg {
            content: '';
            display: block;
            width: 10px;
            height: 7px;
            flex: 0 0 auto;
            margin-left: 18px;
            margin-top: 5px;
            transform: rotate(-90deg);
        }

        &:last-child {
            margin-right: 0;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {

}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {

}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {
    .breadcrumbs {
        flex-wrap: nowrap;

        li {
            margin-right: 10px;
            overflow: hidden;
            flex: 0 0 auto;

            &:last-child {
                margin-right: 0;
                flex: 1 1 auto;
            }

            &::after {
                margin-left: 10px;
            }

            a, span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}