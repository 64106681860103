@import '../../assets/styles/utils/variables';

.Header {
	order: 1;
	width: 100%;
	min-width: $min-width;	
	text-align: left;
	padding: 0;
	background-color: $color-8;
	box-shadow: $shadow-1-start;
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	transition: $default-transition;

	&.scrolled, &.dropDownActive {
		background-color: $color-8;
		box-shadow: $shadow-1;
	}

	.container {
		display: flex;
	}

	.logo {
		flex: 0 0 170px;
		margin-right: $grid-gap;
	}

	.headerLeft {
		display: flex;
		align-items: flex-end;
		flex: 1 1 auto;
		margin-top: 0;
		margin-left: $grid-gap;
	}

	.nav {
		flex: 1 1 auto;
		margin: 0 $grid-gap 0 0;
	}

	.contacts {
		flex: 0 0 auto;
		margin-left: $grid-gap;
		padding-bottom: 22px;
	}

	&.toTop {
		transform: translateY(0%);
	}

	&.toBottom {
		transform: translateY(0%);
	}
}

/*
================================================================================
|                                     MOBILE                                   |
================================================================================
*/

/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media (max-width: $screen-xlg-max) {

}

@media (max-width: $screen-lg-max) {
	.Header {
		.logo {
			flex: 0 0 150px;
		}
	}
}

/* Medium Devices, Desktops */
@media (max-width: $screen-md-max) {
	.Header {
		.container {
			align-items: center;
			position: relative;

			padding: 12px 0;
		}

		.logo {
			display: flex;
			justify-content: center;
			flex: 0 0 100%;
			margin-right: 0;
		}
		
		.hamburger {
			position: absolute;
			left: 0;
			top: 40px;
		}

		.contacts {
			position: absolute;
			top: 40px;
			right: 0;
		}

		&.toTop {
			transform: translateY(0%);
			
			[class*=navMobile] {
				transform: translateY(0);
			}
		}
	
		&.toBottom {
			transform: translateY(-100%);

			[class*=navMobile] {
				transform: translateY(84px);
			}
		}
	}
}

/* Small Devices, Tablets */
@media (max-width: $screen-sm-max) {

}

/* Extra Small Devices, Phones */
@media (max-width: $screen-xs-max) {

}

/* Custom, iPhone Retina */
@media (max-width: $screen-xxs-max) {

}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media (min-width: $screen-xs-min) {

}

/* Extra Small Devices, Phones */
@media (min-width: $screen-sm-min) {

}

/* Small Devices, Tablets */
@media (min-width: $screen-md-min) {

}

/* Medium Devices, Desktops */
@media (min-width: $screen-lg-min) {

}

 /* Large Devices, Wide Screens */
@media (min-width: $screen-xl-min) {

}